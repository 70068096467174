import { useContext, useEffect } from "react";
import LanguageContext from '../../context/index'
import "./about.scss"
import TSParticles from '../../components/TSParticles'
import BoxTextLeft from '../../components/BoxTextLeft'
import BoxTextRight from '../../components/BoxTextRight'
import '../../sass/_keyframes.scss';
import SEO from '../../components/SEO'
import {RxDotFilled}  from 'react-icons/rx'

export default function About(){
    const { lang } = useContext(LanguageContext)

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);


    return(
        <div className="about_wrap">
            <SEO
                title={lang === 'fr' ? 'À propos de Nathan Willcocks' : 'About Nathan Willcocks'}
                description= {lang === 'fr' ? "Présentation de l'acteur Nathan Willcocks" : 'Presentation of the actor Nathan Willcocks'}
                name='Nathan Willcocks'
                type='article'
            />
            <TSParticles/>
            {lang === 'fr' ?
                <div className="about_text_wrap">
                    <div className="about_text_wrap_left">
                        <BoxTextLeft
                        text= {<p>Nathan Willcocks est un acteur franco-britannique. Après une <strong>importante carrière au théâtre en Angleterre et à l'étranger, il se tourne vers le cinéma et la télévision</strong>.
                            <br/>Nathan Willcocks a travaillé avec des cinéastes reconnus dans le cinéma d'auteur depuis de nombreuses années notamment Morgan Simon dans <strong>Compte tes blessures incarnant un des rôles principaux</strong>, et Raphaël Jacoulot dans L'Enfant rêvé avec Louise Bourgoin et Jalil Lespert. On le retrouve aux côtés de Vincent Lindon dans Dernier Amour et de Woody Harrelson dans Lost in London.
                            <br/>À la télévision, il fait partie du casting international de la nouvelle série Canal+ Marie-Antoinette, en interprétant le personnage récurrent du Comte de Mercy, diffusée dans 167 pays.
                            <br/>En 2024, <strong>Nathan Willcocks interprète le ministre de la défense Philippe Bardin dans la nouvelle série internationale Paris Has Fallen</strong>, actuellement diffusée sur Canal+ et sur Amazon Prime prochainement.                            
                        </p>}
                        />
                    </div>
                    <div className="about_text_wrap_right">
                        <BoxTextRight
                        text= {<p>
                            Depuis plus de 10 ans, en parallèle de sa carrière d'acteur, Nathan Willcocks prête sa voix à de nombreux documentaires, publicités et films d'animation primés dont Impunité, Wild France, Les Routes de l’Esclavage, The Sanctuary, Vogue, Le Mans 1955 pour n'en citer que quelques-uns.
                        </p>}
                        />
                    </div>
                    <div className="about_text_wrap_left">
                        <BoxTextLeft
                        text= {<div>
                            <p className="first_p">ACTUALITÉS CINÉMA & TV</p>
                            <p><span>Paris Has Fallen</span> (Canal +) <RxDotFilled/> <span>Marie-Antoinette</span> (Canal +) <RxDotFilled/> <span>Versailles</span> (Canal +) <RxDotFilled/> <span>Missions</span> (OCS) <RxDotFilled/> <span>Calls</span> (Canal +) <RxDotFilled/> <span>Compte tes Blessures</span> (Morgan Simon) <RxDotFilled/> <span>Lost in London</span> (Woody Harrelson) <RxDotFilled/> <span>Dernier Amour</span> (Benoît Jacquot) <RxDotFilled/> <span>L'Enfant Rêvé</span> (Raphaël Jacoulot) <RxDotFilled/> <span>Suzanna Andler</span> (Benoît Jacquot)</p>
                        </div>}
                        />
                    </div>
                </div>
                :
                <div className="about_text_wrap">
                    <div className="about_text_wrap_left">
                        <BoxTextLeft
                        text= {<p>
                            Nathan Willcocks is an English & French actor. After an <strong>extensive career in the theatre in the UK and abroad, he turned to film & television</strong>. 
                            <br/>Nathan Willcocks has worked with many acclaimed independent filmmakers over the years, notably in Europe. Recently he portrayed <strong>one of the lead characters in Morgan Simon's film A Taste Of Ink</strong> (Special mention at the San Sebastián International Film Festival), Raphael Jacoulot's Dreamchild with Louise Bourgoin & Jalil Lespert. He co-starred alongside Vincent Lindon in Casanova Last Love, and joined the cast in Woody Harrelson's Lost in London. 
                            <br/>On television, recent work includes the BBC and Canal+ series Marie-Antoinette, as one of the main characters, Count Mercy, broadcast in 167 countries.
                            <br/>In 2024, <strong>Nathan Willcocks guest stars as Philippe Bardin ( Minister of defence ) in the new international hit series Paris Has Fallen</strong>, currently on Canal+  and on Amazon Prime UK in November. 
                        </p>}
                        />
                    </div>
                    <div className="about_text_wrap_right">
                        <BoxTextRight
                        text= {<p>
                            For more than 10 years, in parallel to his acting career Nathan Willcocks has lent his voice to numerous award-winning documentaries, commercials and animated films, such as Impunity, Wild France, Slavery Routes, The Sanctuary, Vogue, Le Mans 1955 to name a few.
                        </p>}
                        />
                    </div>
                    <div className="about_text_wrap_left">
                        <BoxTextLeft
                        text= {<div>
                            <p className="first_p">RECENT FILM & TV CREDITS</p>
                            <p><span>Paris Has Fallen</span> (Canal +) <RxDotFilled/> <span>Marie-Antoinette</span> (Canal +) <RxDotFilled/> <span>Versailles</span> (Canal +) <RxDotFilled/> <span>Missions</span> (OCS) <RxDotFilled/> <span>Calls</span> (Canal +) <RxDotFilled/> <span>A Taste of Ink</span> (Morgan Simon) <RxDotFilled/> <span>Lost in London</span> (Woody Harrelson) <RxDotFilled/> <span>Casanova Last Love</span> (Benoît Jacquot) <RxDotFilled/> <span>Dreamchild</span> (Raphaël Jacoulot) <RxDotFilled/> <span>Suzanna Andler</span> (Benoît Jacquot)</p>
                        </div>}
                    />
                    </div>
                </div>
            }
        </div>
    )
}