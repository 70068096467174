import "./contact.scss";
import plane from '../../assets/plane2.webp';
import LanguageContext from '../../context/index'
import { useContext } from 'react'
import TSParticles from '../../components/TSParticles'
import Imdb from '../../assets/imdb.webp'
import Spotlight from '../../assets/spotlight.webp'
import Insta from '../../assets/Instagram.webp'
import SEO from '../../components/SEO'
import Sue from '../../assets/sueTerry.webp';
import SueInt from '../../assets/sueTerryInternational.webp';

export default function Contact(){
    const { lang } = useContext(LanguageContext)

    return(
        <div className="contact_box">
            <SEO
                title={lang === 'fr' ? 'Contacter Nathan Willcocks' : 'Contact Nathan Willcocks'}
                description= {lang === 'fr' ? "Comment contacter Nathan Willcocks" : 'How to contact Nathan Willcocks'}
                name='Nathan Willcocks'
                type='article'
            />
            <TSParticles/>   
            <div className="contact_img">
                <img className="plane" src={plane} alt="paper plane"/>
            </div>
            <div className="contact_wrap">
                <div className="contact_background">
                    <div className="contact_text">
                        <div className="contact_text_box">
                            {lang === 'fr' ? <h4>Agence voix-off</h4> : <h4>Voice-over agency</h4>}
                            <p>
                                {lang === 'fr' ? <span>Agence Sue Terry</span> : <span>Sue Terry Agency</span>}
                                <br/><a href="mailto:sue@sueterryvoices.com">sue@sueterryvoices.com</a>
                                <br/>+44 (0)20 7434 2040
                            </p>
                        </div>
                        <div className="contact_text_box">
                            {lang === 'fr' ? <h4>Agence artistique</h4> : <h4>Artistic agency</h4>}
                            <p>
                                {lang === 'fr' ? <span>Agence UBBA</span> : <span>UBBA Agency</span>}
                                <br/>Jean-Baptiste L'Herron
                                <br/>Asst : Manon Ritzenthaler
                                <br/><a href="mailto:m.ritzenthaler@ubba.eu">m.ritzenthaler@ubba.eu</a>
                                <br/>+33 1 44 54 26 40
                            </p>
                        </div>
                        <div className="logos_about_box">
                            <a href="https://www.sueterryvoices.com/artist/nathan-willcocks-2" target="_blank" rel="noreferrer" title="Sue Terry Voices International"><img src={SueInt} alt="SueTerry Voices international" className="sueTerry_logo" /></a>
                            <a href="https://www.sueterryvoices.com/artist/nathan-willcocks" target="_blank" rel="noreferrer" title="Sue Terry Voices EN"><img src={Sue} alt="SueTerry Voices english" className="sueTerry_logo" /></a>
                            <a href="https://www.spotlight.com/7810-9050-8776" target="_blank" rel="noreferrer" title="Spotlight"><img src={Spotlight} alt="Spotlight" className="spotlight_logo" /></a>
                            <a href="https://www.imdb.com/name/nm1229301/" target="_blank" rel="noreferrer" title="IMDB"><img src={Imdb} alt="IMDb" className="imdb_logo" /></a>
                            <a href="https://www.instagram.com/nathanwillcocks/" target="_blank" rel="noreferrer" title="Instagram"><img src={Insta} alt="INSTAGRAM" className="instagram_logo" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}